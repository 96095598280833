import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./reducer/";
import loginApi from "./loginApi";
import docApi from "./docApi";
import paymentApi from "./paymentApi";
import productApi from "./productApi";
import userApi from "./userApi";
import orderApi from "./orderApi";
import sysApi from "./sysApi";



const persistConfig = {
  key: "root",
  storage,
  // 配置持久化存在白名单
  whitelist: ["user", "doc"],
};


const sliceReducer = combineReducers({
  [docApi.reducerPath]: docApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [orderApi.reducerPath]: orderApi.reducer,
  [sysApi.reducerPath]: sysApi.reducer,
  [loginApi.reducerPath]: loginApi.reducer, ...reducer,
});

const persistedReducer = persistReducer(persistConfig, sliceReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (middle) =>
    middle({serializableCheck: false})
      .concat(
        [
          docApi.middleware,
          loginApi.middleware,
          paymentApi.middleware,
          productApi.middleware,
          userApi.middleware,
          orderApi.middleware,
          sysApi.middleware
        ])
});

const persistor = persistStore(store);

export { store, persistor };

