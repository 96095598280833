import { userReducer } from "./userSlice";
import { docReducer } from "./docSlice";
import {productReducer} from "./ProductSlice";
import {resultReducer} from "./resultSlice";
import {orderReducer} from "./orderSlice";
import {recordReducer} from "./recordSlice";
import {searchDocReducer} from "./searchDocSlice";

const AllReducer = {
  user: userReducer,
  doc: docReducer,
  product: productReducer,
  order: orderReducer,
  result: resultReducer,
  record: recordReducer,
  searchDoc: searchDocReducer
};

export default AllReducer;
