import { Suspense, lazy } from "react";

import Layout from "../components/layout";
import LoginPage from "../login";

const Home = lazy(() => import("../home"));
const Order = lazy(() => import("../order"));
const DownRecord = lazy(() => import("../record"));
const Search = lazy(() => import("../search"));

const LayoutComponent = ({ children }: any) => {
  return (
    <Suspense fallback={""}>
      <Layout />
    </Suspense>
  );
};

export interface RouteConfig {
  path: string;
  element: React.ReactNode;
  auth: boolean;
  children?: RouteConfig[];
  redirect?:string
}

export const routers = [
  { path: "/login", element: <LoginPage />, auth: false },
  {
    path: "/",
    element: <Layout />,
    auth: true,
    children: [
      { path: "/home", element: <Home />, auth: true },
      { path: "/order", element: <Order />, auth: true },
      { path: "/record", element: <DownRecord />, auth: true },
      { path: "/search", element: <Search />, auth: true },
    ],
  },
];