import {Button, Input, Modal, Typography} from 'antd'

import { Card } from '../card'
import Icon from '../icon'
import styles from './index.module.css'
import avatar from '../../assets/avatar.jpg'
import vip1 from '../../assets/v1.png'
import vip2 from '../../assets/v2.png'
import vip3 from '../../assets/v3.png'
import vip from '../../assets/v.png'
import wechat_logo from '../../assets/wechat-logo.png'
import weixin from '../../assets/weixin.png'
import selected from '../../assets/selected.png'
import { useDispatch, useSelector } from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import { useProductListMutation } from "../../store/productApi";
import { usePlanOrderMutation } from "../../store/paymentApi";
import { useNavigate } from "react-router-dom";
import {Product, setProducts, setSelectedProduct} from "../../store/reducer/ProductSlice";
import EditPasswordModal from "../password";
import {useMonitorOrderStateMutation} from "../../store/orderApi";
import {useGetUserMutation} from "../../store/userApi";
import {setUser} from "../../store/reducer/userSlice";
import ResultModal from "../result";
import {setResult} from "../../store/reducer/resultSlice";
import {setDocs, setTotal} from "../../store/reducer/docSlice";
import {useDocsListMutation} from "../../store/docApi";
import {useLogoutMutation} from "../../store/loginApi";

const { Title, Text } = Typography

class PaymentInfo {
  orderId: string | null
  name: string | null
  base64: string | ''
  price: number | null
  constructor(orderId: string | null, name: string | null, base64: string | '', price: number | null) {
    this.orderId = orderId
    this.name = name
    this.base64 = base64;
    this.price = price
  }
}

const LayoutRight = () => {
  const mobile = useSelector((state: any) => state.user.mobile);
  const expired = useSelector((state: any) => state.user.expired);
  const acitvate = useSelector((state: any) => state.user.activate);
  const products = useSelector((state: any) => state.product.products);
  const currentProductId = useSelector((state: any) => state.product.currentProductId);
  const [memberOpen, setMemberOpen] = useState(false);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [productList ] = useProductListMutation()
  const [planOrder, { isLoading} ] = usePlanOrderMutation()
  const [monitorOrderState] = useMonitorOrderStateMutation()
  const [docList] = useDocsListMutation()
  const [logout] = useLogoutMutation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getUserInfo] = useGetUserMutation()
  const [payment, setPayment] = useState(new PaymentInfo(null, null, '', null))


  const showPassword:any = useRef()
  const resultRef: any = useRef()

  {/* 页面初始化会员充值数据 */}
  useEffect(() => {
    const fetchData = async () => {
      const resp:any = await productList(null);
      if (resp.data?.code === 10003 || resp.data?.code === 10017) {
        localStorage.clear();
        navigate("/login")
        return
      } else if (resp.data.code === 0) {
        dispatch(setProducts(resp.data.data))
      } else {
        dispatch(setResult({title:'获取数据失败', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
        resultRef.current.showResultModal(true)
      }
    }
    fetchData()
  }, [])

  {/* 会员充值弹框关闭 */}
  const memberCancel = () => {
    setMemberOpen(false)
  }

  {/* 支付信息弹框关闭 */}
  const paymentCancel = () => {
    setPaymentOpen(false)
  }

  {/* 选择会员产品类型 */}
  const pay = (item: Product) => {
    if (item.key != null) {
      dispatch(setSelectedProduct(item.key))
    }
  }

  {/*开通/续费会员*/}
  const recharge = async () => {
    if (currentProductId === null || currentProductId === '') {
      dispatch(setResult({title: '开通会员', state: 'error', msg: '请选择一个产品', code: 0}))
      resultRef.current.showResultModal(true)
      return
    }
    const resp:any = await planOrder(currentProductId)
    switch (resp.data.code) {
      case 10003:
        localStorage.clear();
        navigate("/login")
        return
      case 0:
        setPayment(resp.data.data)
        memberCancel()
        setPaymentOpen(true)
       {/* 轮训监听订单状态 */}
        monitorOrder(resp.data.data.orderId)
        break;
      default:
        dispatch(setResult({title:'创建订单失败', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
        resultRef.current.showResultModal(true)
        break;
    }
  }

  {/* 监听订单状态 */}
  const monitorOrder = async (orderId: string) => {
    const timer = setInterval(async () => {
      const resp:any = await monitorOrderState(orderId)
      console.log('监听订单状态数据: ', resp)
      switch (resp.data.code) {
        case 10003:
          clearInterval(timer)
          localStorage.clear();
          navigate("/login")
          return
        case 0:
          if (resp.data.data) {
            synchronizeUserInfo()
            clearInterval(timer)
          }
          break;
        default:
          dispatch(setResult({title:'获取订单信息失败', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
          resultRef.current.showResultModal(true)
          break;
      }
    }, 2000)
  }

  {/* 同步用户信息 */}
  const synchronizeUserInfo = async () => {
    const resp:any = await getUserInfo(null)
    switch (resp.data.code) {
      case 10003:
        localStorage.clear();
        navigate("/login")
        return
      case 0:
        dispatch(setUser({mobile: resp.data.data.nickname, expired: resp.data.data.expired, loginState: true, activate: resp.data.data.activate}))
        paymentCancel()
        getDocList()
        break;
      default:
        dispatch(setResult({title:'获取用户信息失败', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
        resultRef.current.showResultModal(true)
        break;
    }
  }

  {/*获取文档列表*/}
  const getDocList = async () => {
    const data = {page: 1}
    const resp: any = await docList(data)
    if (resp.data?.code === 10003 || resp.data?.code === 10017) {
      localStorage.clear();
      navigate("/login")
      return
    } else if (resp.data.code === 0) {
      dispatch(setDocs(resp.data.data.doc))
      dispatch(setTotal(resp.data.data.total))
    } else {
      dispatch(setResult({title:'搜索查询失败', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
      resultRef.current.showResultModal(true)
    }
  }

  {/* 显示修改密码弹框 */}
  const showPasswordModal = () => {
    // @ts-ignore
    showPassword.current.showPassword(true)
  }

  {/* 退出 */}
  const logoutClick = async () => {
    await logout(null)
    localStorage.clear()
    navigate("/login")
  }

  return (
    <>
      <Card>
        <div className={styles.personal_header}>
          <img
            src={avatar}
            alt='avatar'
            width={100}
            height={100}
            style={{ borderRadius: '50%' }}
          />
          <Title level={2} style={{fontSize: 16}}>{mobile}</Title>
        </div>
        <div className={styles.personal_title}>
          <div>
            <div>
              <Text type='secondary'>会员到期时间</Text>{' '}
            </div>
            <div>
              <Text type='secondary' style={{color: '#ff606e', fontWeight: 600}}>{acitvate ? expired : '游客'}</Text>
            </div>
          </div>
        </div>
        <Button
          type='primary'
          style={{ width: '100%', marginTop: 16 }}
          onClick={() => setMemberOpen(true)}
        >
          续费
        </Button>
        <div className={styles.menu}>
          <Button style={{width: 80}} type="link" onClick={() => navigate("/home")}>首页</Button>
          <Button style={{width: 80}} type="link" onClick={() => navigate("/order")}>我的订单</Button>
          <Button style={{width: 80}} type="link" onClick={() => navigate("/record")}>下载记录</Button>
          <Button style={{width: 80}} type="link" onClick={showPasswordModal}>修改密码</Button>
        </div>
        <Button
          type='primary'
          style={{ width: '100%', marginTop: 16, backgroundColor: '#333'}}
          onClick={logoutClick}
        >
          退出
        </Button>
      </Card>
      <Card>
        <div className={styles.personal_header}>
          <Title level={3} style={{fontSize: 16, textAlign: 'center', color: '#ff606e'}}>防失联</Title>
          <img
            src={weixin}
            alt='avatar'
            width={100}
            height={100}
          />
          <p style={{fontSize: 14, marginTop: 5}}>微信扫一扫</p>
        </div>
      </Card>
      <Card>
        <div>
          <Icon className={styles.icon_shape} name='fa-bullhorn' /> 公告
        </div>
        <Text type='secondary'>暂无</Text>
      </Card>
      <Modal title="会员充值"
             centered
             open={memberOpen}
             onCancel={memberCancel}
             footer={[]}
             width={710}
      >
        <div className={styles.member_list}>
          {
            products.map((item: Product) => {
              return (
                <div
                  className={`${styles.member_item} ${item.show ? styles.member_item_selection : ''}`}
                  key={item.key}
                  onClick={() => pay(item)}>
                  <div className={styles.member}>
                    <div className={styles.vip}>
                      <img
                        src={`${item.productName === '一年会员' ? vip1 : item.productName === '二年会员' ? vip2 : item.productName === '三年会员' ? vip3 : vip}`}
                        alt="VIP图标"/>
                    </div>
                    <div className={styles.price}>
                      <p>原价:{item.oldPrice}</p>
                      <p>{item.price}</p>
                    </div>
                  </div>
                  <div className={styles.desc}>
                    <p>
                      <span>{item.productName}</span>
                      <span>开通会员享受VIP文档免费下载</span>
                    </p>
                  </div>

                  <div className={`${styles.selectedIcon} ${item.show ? '' : styles.isShowIcon}`}>
                    <img src={selected} alt={`icon`}/>
                  </div>
                  <Input type={`hidden`} value={currentProductId}/>
                </div>
              )
            })
          }
          <div className={styles.recharge}>
            <Button type="primary" loading={isLoading} onClick={recharge}>充 值</Button>
          </div>
        </div>
      </Modal>

      <Modal title="支付信息"
             centered
             open={paymentOpen}
             onCancel={paymentCancel}
             footer={[]}
             width={500}
      >
        <div>
          <div className={styles.payment_qrcode}>
            <h5><img src={wechat_logo} alt="微信图标"/></h5>
            <div style={{position: 'relative', textAlign: 'center'}}>
              <img src={payment.base64} style={{width: 230, height: 230, marginBottom: 2}} alt="支付二维码"/>
              <div className={styles.payment_title}>
                <span>{payment.name}</span>
                <span style={{color: '#ff606e', marginLeft: 5}}>¥{payment.price}</span>
              </div>
            </div>
            <div className={styles.payment_bottom}>
              请使用微信扫一扫
              <br/>
              扫描二维码支付
            </div>
          </div>
        </div>
      </Modal>

      <EditPasswordModal ref={showPassword}/>
      <ResultModal ref={resultRef}/>
    </>
  )
}

export default LayoutRight