import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {MONITOR_ORDER_STATE, ORDER_LIST} from "../api/api";
import { baseQuery, InterceptorsResponse } from "../api/baseQuery";

// 订单接口
const orderApi = createApi({
  reducerPath: "orderApi", // Api的标识，不能和其他的Api或reducer重复
  baseQuery: baseQuery,
  endpoints(build) {
    return {
      // 订单列表
      orderList: build.mutation({
        query(payload) {
          return {
            url: ORDER_LIST,
            method: 'POST',
            body: payload,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          };
        },
        transformResponse: InterceptorsResponse
      }),
      // 订单列表
      monitorOrderState: build.mutation({
        query(orderId: string) {
          return {
            url: MONITOR_ORDER_STATE + `?orderId=${orderId}`,
          };
        },
        transformResponse: InterceptorsResponse
      }),
    };
  },
});
export const {
  useOrderListMutation ,
  useMonitorOrderStateMutation
} = orderApi;

export default orderApi;
