import  { createSlice } from "@reduxjs/toolkit";
import  type {PayloadAction} from "@reduxjs/toolkit";
import React from "react";

export class Product {
  key: React.Key | null
  productName: string | null
  oldPrice: number | null
  price: number | null
  desc: string | null
  show: boolean

  constructor(key: string | null, productName: string | null, oldPrice: number | null, price: number | null, desc: string | null, show: boolean) {
    this.key = key;
    this.productName = productName;
    this.oldPrice = oldPrice;
    this.price = price;
    this.desc = desc;
    this.show = show
  }
}

export interface DocState {
  products: Array<Product>
  currentProductId: React.Key | null
}


const initialState: DocState = {
  products: [],
  currentProductId: null
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts(state, action: PayloadAction<Array<Product>>) {
      state.products = [...action.payload]
    },
    setSelectedProduct(state, action: PayloadAction<React.Key>) {
      const newProducts = state.products
      newProducts.forEach((item: Product) => {
        item.show = item.key === action.payload;
      })
      state.products = [...newProducts]
      state.currentProductId = action.payload
    }
  },
})

export const {
  setProducts,
  setSelectedProduct,
} = productSlice.actions
export const { reducer: productReducer } = productSlice;