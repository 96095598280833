import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {FORGOT_PASSWORD, MODIFY_PASSWORD, REGISTER, USER_INFO} from "../api/api";
import {baseQuery, InterceptorsResponse} from "../api/baseQuery";

{/* 用户接口 */}
const userApi = createApi({
  reducerPath: "userApi", // Api的标识，不能和其他的Api或reducer重复
  baseQuery: baseQuery,
  endpoints(build) {
    return {
      // 修改密码
      modifyPassword: build.mutation({
        query(payload) {
          return {
            url: MODIFY_PASSWORD,
            method: 'POST',
            body: payload,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          };
        },
        transformResponse: InterceptorsResponse
      }),
      // 用户注册
      register: build.mutation({
        query(payload) {
          return {
            url: REGISTER,
            method: 'POST',
            body: payload,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          };
        },
        transformResponse: InterceptorsResponse
      }),
      // 忘记密码
      forgotPassword: build.mutation({
        query(payload) {
          return {
            url: FORGOT_PASSWORD,
            method: 'POST',
            body: payload,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          };
        },
        transformResponse: InterceptorsResponse
      }),
      // 获取用户信息
      getUser: build.mutation({
        query(payload) {
          return {
            url: USER_INFO,
          };
        },
        transformResponse: InterceptorsResponse
      }),
    };
  }, // endpoints 用来指定Api中的各种功能，是一个方法，需要一个对象作为返回值
});

// Api对象创建后，对象中会根据各种方法自动的生成对应的钩子函数
// 通过这些钩子函数，可以来向服务器发送请求
// 钩子函数的命名规则 fetchLogin --> useFetchLoginQuery
export const {
  useModifyPasswordMutation ,
  useRegisterMutation,
  useForgotPasswordMutation,
  useGetUserMutation,
} = userApi;

export default userApi;
