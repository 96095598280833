import {Col, Row} from 'antd'
import Search from "antd/es/input/Search";
import React, {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useSearchMutation } from '../../store/docApi'
import {setDocs, setTotal, setKeyword, setKeywords, setCurrentPage} from "../../store/reducer/searchDocSlice";
import {useNavigate} from "react-router-dom";
import ResultModal from "../result";
import {setResult} from "../../store/reducer/resultSlice";



const Header = () => {
  const resultRef: any = useRef()
  const [searchApi ] = useSearchMutation()
  const dispatch = useDispatch();
  const keyword = useSelector((state: any) => state.doc.keyword);
  const navigate = useNavigate();

  {/* 搜索查询 */}
  const onSearch = async (e: string) => {
    dispatch(setKeyword(e))
    const data = {
      keyword: e,
      page: 1,
      row: 20
    }
    const resp: any = await searchApi(data)
    switch (resp.data.code) {
      case 10003 || 10017:
        localStorage.clear();
        navigate("/login")
        break
      case 0:
        navigate("/search")
        dispatch(setDocs(resp.data.data.doc))
        dispatch(setTotal(resp.data.data.total))
        dispatch(setKeyword(resp.data.data.keyword))
        dispatch(setKeywords(resp.data.data.keywords))
        dispatch(setCurrentPage(1))
        break;
      default:
        dispatch(setResult({title:'搜索查询失败', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
        resultRef.current.showResultModal(true)
        break;
    }
  }

  return (
    <Row justify='center' align='middle'>
      <Col span={10} >
        <Search
          placeholder="请输入关键词，多个关键词用空格隔开"
          allowClear
          enterButton="搜索"
          size="large"
          onSearch={onSearch}
          defaultValue={keyword}
        />
        <ResultModal ref={resultRef}/>
      </Col>
    </Row>
  )
}
export default Header