import  { createSlice } from "@reduxjs/toolkit";
import  type {PayloadAction} from "@reduxjs/toolkit";
import React from "react";

export class Doc {
  key: React.Key
  docName: string
  createTime: string
  constructor(key: React.Key, docName: string, createTime: string) {
    this.key = key;
    this.docName = docName;
    this.createTime = createTime;
  }
}

export interface DocState {
  documents: Array<Doc>
  total: number
}


const initialState: DocState = {
  documents: [],
  total: 0
}

export const docSlice = createSlice({
  name: 'doc',
  initialState,
  reducers: {
    setDocs(state, action: PayloadAction<Array<Doc>>) {
      state.documents = [...action.payload]
    },
    setTotal(state, action: PayloadAction<number>){
      state.total = action.payload
    }
  },
})

export const {
  setDocs,
  setTotal,
} = docSlice.actions
export const { reducer: docReducer } = docSlice;