import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { PRODUCT_LIST } from "../api/api";
import { baseQuery, InterceptorsResponse } from "../api/baseQuery";

// 登录接口
const productApi = createApi({
  reducerPath: "productApi", // Api的标识，不能和其他的Api或reducer重复
  baseQuery: baseQuery,
  endpoints(build) {
    return {
      //
      productList: build.mutation({
        query(args:any) {
          return {
            url: PRODUCT_LIST,
          };
        },
        transformResponse: InterceptorsResponse
      }),
    };
  }, // endpoints 用来指定Api中的各种功能，是一个方法，需要一个对象作为返回值
});

// Api对象创建后，对象中会根据各种方法自动的生成对应的钩子函数
// 通过这些钩子函数，可以来向服务器发送请求
// 钩子函数的命名规则 fetchLogin --> useFetchLoginQuery
export const { useProductListMutation } = productApi;

export default productApi;
