import  { createSlice } from "@reduxjs/toolkit";
import  type {PayloadAction} from "@reduxjs/toolkit";
import {stat} from "fs";

export interface UserState {
  mobile: string | null
  expired: string | null
  loginState: boolean | null
  activate: boolean
}

const initialState: UserState = {
  mobile: null,
  expired: null,
  loginState: false,
  activate: false
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      state.mobile = action.payload.mobile
      state.expired = action.payload.expired
      state.loginState = action.payload.loginState
      state.activate = action.payload.activate
    }
  },
})

export const { setUser } = userSlice.actions
export const { reducer: userReducer } = userSlice;