import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { message } from "antd";

export const baseUrl = "https://www.sikudoc.com";
// export const baseUrl = "http://localhost:3001";
export interface Result {
  data: any
  code: number
  msg: string
}

export const baseQuery = fetchBaseQuery({
  baseUrl,
  timeout: 30000,
  // 对所有的请求进行预处理
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("officialToken");
    if (token) {
      headers.set("token", `${token}`);
    }
    return headers;
  },
});

// 统一响应拦截器
export const InterceptorsResponse = (res: Result) => {
  try {
    const code = res.code
    const msg = res.msg;

    switch (code) {
      case 0:
        return res
      case 10003:
        return res
      default:
        console.log(code)
        message.error(msg);
        return res
    }
  } catch (error: any) {
    console.log('error')
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    return Promise.reject(error);
  }
};