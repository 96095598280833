import {forwardRef, useImperativeHandle, useState} from "react";
import {Modal, Result} from "antd";
import {ResultStatusType} from "antd/es/result";
import {useSelector} from "react-redux";

export class ResultInfo {
  state: ResultStatusType
  msg: string | null

  constructor(state: ResultStatusType, msg: string | null) {
    this.state = state;
    this.msg = msg;
  }
}

const ResultModal = (props, ref) => {

  const [resultModalOpen, setResultModalOpen] = useState(false)
  const title =  useSelector((state:any) => state.result.title)
  const state =  useSelector((state:any) => state.result.state)
  const msg =  useSelector((state:any) => state.result.msg)
  useImperativeHandle(ref, () => ({
    showResultModal: (show: boolean) => {
      setResultModalOpen(show)
    }
  }));

  {/* 结果信息弹框关闭 */}
  const resultModalCancel = () => {
    setResultModalOpen(false)
  }

  return (
    <>
      <Modal title={title}
             centered
             open={resultModalOpen}
             onCancel={resultModalCancel}
             footer={[]}
             width={500}>
        <Result
          status={state}
          title={msg}
          extra={[
          ]}
        />
      </Modal>
    </>
  )
}

export default forwardRef(ResultModal)