import {Button, Form, Input, Modal} from "antd";
import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import ResultModal from "../result";
import {useModifyPasswordMutation} from "../../store/userApi";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setResult} from "../../store/reducer/resultSlice";


const EditPasswordModal = (props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const resultRef = useRef()
  const [modifyPassword] = useModifyPasswordMutation()

  useImperativeHandle(ref, () => ({
    showPassword: (show: boolean) => {
      setPasswordModal(show)
    }
  }));

  const [passwordModal, setPasswordModal] = useState(false)

  const passwordModalCancel = () => {
    setPasswordModal(false)
  }

  {/* 提交表单 */}
  const onFinish = async (values: any) => {
    const resp:any = await modifyPassword(values)
    switch (resp.data?.code) {
      case 10003 || 10017:
        localStorage.clear();
        navigate("/login")
        return
      case 0:
        dispatch(setResult({title:'修改密码', state: 'success', msg: '修改密码成功', code: resp.data?.code}))
        setPasswordModal(false)
        // @ts-ignore
        resultRef.current.showResultModal(true)
        break;
      default:
        dispatch(setResult({title:'修改密码', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
        // @ts-ignore
        resultRef.current.showResultModal(true)
        break;
    }
  };

  const checkConfirmNewPassword = (rule: any, value: any) => {
    const newPassword = form.getFieldValue('newPassword')
    if (value !== null && value !== '') {
      if (newPassword !== value) {
        return Promise.reject('两次密码不一致');
      } else {
        return Promise.resolve()
      }
    }
  }

  return (
    <>
      <Modal title="修改密码"
             centered
             open={passwordModal}
             onCancel={passwordModalCancel}
             footer={[]}
             width={500}>
        <div style={{marginTop: 20}}>
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="旧密码"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: '请输入您的旧密码',
                },
                {
                  min: 6,
                  message: '密码不能少于4个字符'
                },
                {
                  max: 30,
                  message: '密码不能大于30个字符'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="新密码"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: '请输入您的新密码',
                },
                {
                  min: 6,
                  message: '密码不能少于4个字符'
                },
                {
                  max: 30,
                  message: '密码不能大于30个字符'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="确认新密码"
              name="confirmNewPassword"
              rules={[
                {
                  required: true,
                  message: '请再次输入您的新密码',
                },
                {
                  min: 6,
                  message: '密码不能少于4个字符'
                },
                {
                  max: 30,
                  message: '密码不能大于30个字符'
                },
                {
                  validator:checkConfirmNewPassword
                }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button style={{width: 200, marginTop: 20}} type="primary" htmlType="submit">
                修改密码
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <ResultModal ref={resultRef}/>
    </>
  )
}

export default forwardRef(EditPasswordModal)