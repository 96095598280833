import  { createSlice } from "@reduxjs/toolkit";
import  type {PayloadAction} from "@reduxjs/toolkit";
import React from "react";

export class OrderInfo {
  key: React.Key | null
  name: string | null
  price: number | null
  payType: string | null
  state: string | null
  createTime: string | null

  constructor(key: React.Key | null, name: string | null, price: number | null, payType: string | null, state: string | null, createTime: string | null) {
    this.key = key
    this.name = name;
    this.price = price;
    this.payType = payType;
    this.state = state;
    this.createTime = createTime;
  }
}

export interface OrderState {
  orders: Array<OrderInfo>
  total: number
}


const initialState: OrderState = {
  orders: [],
  total: 0,
}

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrders(state, action: PayloadAction<Array<OrderInfo>>) {
      state.orders = [...action.payload]
    },
    setTotal(state, action: PayloadAction<number>){
      state.total = action.payload
    },
  },
})

export const {
  setOrders,
  setTotal,
} = orderSlice.actions
export const { reducer: orderReducer } = orderSlice;