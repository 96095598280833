import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, Col, Form, Input, Modal, Row} from "antd";
import {useGetCaptchaMutation} from "../store/sysApi";
import {setResult} from "../store/reducer/resultSlice";
import {useDispatch} from "react-redux";
import ResultModal from "../components/result";
import {useForgotPasswordMutation} from "../store/userApi";

const ForgotPassword = (props, ref) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [captchaText, setCaptchaText] = useState('获取验证码')
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false)
  const [count, setCount] = useState(90)
  const latestCount = useRef(90)
  const [forgotPassowordModalOpen, setForgotPassowordModalOpen] = useState(false)
  const [getCaptcha] = useGetCaptchaMutation()
  const [forgotPassword] = useForgotPasswordMutation()
  const resultRef:any = useRef()

  useImperativeHandle(ref, () => ({
    showForgotPassword: (show: boolean) => {
      setForgotPassowordModalOpen(show)
    }
  }));

  useEffect(() => {
    latestCount.current = count;
  }, [count]);

  {/* 注册弹框关闭 */}
  const forgotPasswordCancel = () => {
    setForgotPassowordModalOpen(false)
  }

  {/* 发送手机验证码 */}
  const sendSms = async () => {
    const mobile = form.getFieldValue('mobile')
    if (mobile) {
      const resp: any = await getCaptcha(mobile)
      if (resp.data.code === 0) {
        countdown()
      } else {
        dispatch(setResult({title:'发送验证码失败', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
        resultRef.current.showResultModal(true)
      }
    }
  }

  {/* 计时器 */}
  const countdown = () => {
    const timer = setInterval(() => {
      if (latestCount.current === 0) {
        clearInterval(timer);
        setCount(90)
        setCaptchaText('获取验证码')
        setSendButtonDisabled(false)
        return;
      } else {
        setSendButtonDisabled(true)
        setCaptchaText('等待' + latestCount.current + '秒')
      }
      setCount(c => c -1 );
    }, 1000);
  }

  const onFinish = async (values: any) => {
    const resp: any = await forgotPassword(values)
    console.log('resp: ', resp)
    if (resp.data?.code !== 0) {
      dispatch(setResult({title:'修改密码失败', state: 'error', msg: resp.data?.msg, code: resp.data?.code}))
      resultRef.current.showResultModal(true)
      return
    } else {
      dispatch(setResult({title:'修改密码成功', state: 'success', msg: '密码修改成功', code: resp.data?.code}))
      resultRef.current.showResultModal(true)
      setForgotPassowordModalOpen(false)
    }
  };

  return (
    <>
      <Modal title="忘记密码"
             centered
             open={forgotPassowordModalOpen}
             onCancel={forgotPasswordCancel}
             footer={[]}
             width={500}>
        <div>
          <Form
            name="register"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            form={form}
          >
            <Form.Item
              label="手机号"
              name="mobile"
              rules={[
                {
                  required: true,
                  message: '请输入手机号!' },
                {
                  pattern: /^1(3[0-9]|4[01456879]|5[0-3,5-9]|6[2567]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/,
                  message: '请输入正确的手机号'
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="密码"
              name="password"
              rules={[
                {
                  required: true,
                  message: '请输入密码!'
                },
                {
                  min: 6,
                  message: '密码不能少于4个字符'
                },
                {
                  max: 30,
                  message: '密码不能大于30个字符'
                }
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="验证码"
              name="captcha"
              rules={[{ required: true, message: '请输入验证码!' }]}
            >
              <Row gutter={12}>
                <Col span={15}>
                    <Input />
                </Col>
                <Col span={8}>
                  <Button style={{width: 105}} onClick={sendSms} disabled={sendButtonDisabled}>{captchaText}</Button>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
              <Button style={{width: 200, marginTop: 20}} type="primary" htmlType="submit">
                修改密码
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <ResultModal ref={resultRef}/>
    </>
  )
}

export default forwardRef(ForgotPassword)