import  { createSlice } from "@reduxjs/toolkit";
import  type {PayloadAction} from "@reduxjs/toolkit";
import React from "react";

export class RecordInfo {
  key: React.Key | null
  docName: string | null
  createTime: string | null

  constructor(key: React.Key | null, docName: string | null, createTime: string | null) {
    this.key = key;
    this.docName = docName;
    this.createTime = createTime;
  }
}

export interface RecordState {
  records: Array<RecordInfo>
  total: number
}


const initialState: RecordState = {
  records: [],
  total: 0,
}

export const recordSlice = createSlice({
  name: 'record',
  initialState,
  reducers: {
    setRecords(state, action: PayloadAction<Array<RecordInfo>>) {
      state.records = [...action.payload]
    },
    setTotal(state, action: PayloadAction<number>){
      state.total = action.payload
    },
  },
})

export const {
  setRecords,
  setTotal,
} = recordSlice.actions
export const { reducer: recordReducer } = recordSlice;