{/* 登录 */}
export const LOGIN = '/login.do';
export const LOGOUT = '/logout.do';
export const DOC_LIST = '/doc/list.do'
{/* 获取文档详情 */}
export const DOC_DETAIL = '/doc/detail.do'
{/* 产品列表 */}
export const PRODUCT_LIST = '/product/list.do'
{/* 下单支付 */}
export const PAYMENT = '/order/payment.do'
{/* 搜索 */}
export const SEARCH = '/doc/search.do'
{/* 修改密码 */}
export const MODIFY_PASSWORD = '/user/modifyPassword.do'
{/* 订单列表 */}
export const ORDER_LIST = '/order/list.do'
{/* 下载记录 */}
export const RECORD_LIST = '/doc/records.do'
{/* 发送手机验证码 */}
export const SEND_MOBILE_CAPTCHA = '/sys/send/sms.do'
{/* 新用户注册*/}
export const REGISTER = '/register.do'
{/* 忘记密码(手机验证码修改) */}
export const FORGOT_PASSWORD = '/forgot/password.do'
{/* 用户简单的用户信息 */}
export const USER_INFO = '/user/info.do'
{/* 监听订单状态 */}
export const MONITOR_ORDER_STATE = '/order/orderState.do'