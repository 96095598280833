import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {DOC_DETAIL, DOC_LIST, RECORD_LIST, SEARCH} from "../api/api";
import {baseQuery, InterceptorsResponse} from "../api/baseQuery";

// 登录接口
const docApi = createApi({
  reducerPath: "docApi", // Api的标识，不能和其他的Api或reducer重复
  baseQuery: baseQuery,
  endpoints(build) {
    return {
      // 文档列表
      docsList: build.mutation({
        query(payload) {
          return {
            url: DOC_LIST,
            method: 'POST',
            body: payload,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          };
        },
        transformResponse: InterceptorsResponse
      }),
      // 文档详情
      docDetail: build.mutation({
        query(docId: string) {
          return {
            url: DOC_DETAIL + `?docId=${docId}`
          }
        },
        transformResponse: InterceptorsResponse
      }),
      // 搜索文档
      search: build.mutation({
        query(payload) {
          return {
            url: SEARCH,
            method: 'POST',
            body: payload,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          };
        },
      }),
      // 下载记录
      recordList: build.mutation({
        query(payload) {
          return {
            url: RECORD_LIST,
            method: 'POST',
            body: payload,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          };
        },
      }),
    };
  }, // endpoints 用来指定Api中的各种功能，是一个方法，需要一个对象作为返回值
});

// Api对象创建后，对象中会根据各种方法自动的生成对应的钩子函数
// 通过这些钩子函数，可以来向服务器发送请求
// 钩子函数的命名规则 fetchLogin --> useFetchLoginQuery
export const {
  useDocsListMutation,
  useDocDetailMutation,
  useSearchMutation ,
  useRecordListMutation,
} = docApi;

export default docApi;
