import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {LOGIN, LOGOUT} from "../api/api";
import {baseUrl, InterceptorsResponse} from "../api/baseQuery";

// 登录接口
const loginApi = createApi({
  reducerPath: "loginApi", // Api的标识，不能和其他的Api或reducer重复
  baseQuery: fetchBaseQuery({
    baseUrl
  }),
  endpoints(build) {
    return {
      // 登录
      login: build.mutation({
        query(payload) {
          return {
            url: LOGIN,
            method: 'POST',
            body: payload,
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          };
        },
        transformResponse: InterceptorsResponse
      }),
      // 退出
      logout: build.mutation({
        query(payload) {
          return {
            url: LOGOUT,
          };
        },
        transformResponse: InterceptorsResponse
      }),
    };
  }, // endpoints 用来指定Api中的各种功能，是一个方法，需要一个对象作为返回值
});

// Api对象创建后，对象中会根据各种方法自动的生成对应的钩子函数
// 通过这些钩子函数，可以来向服务器发送请求
// 钩子函数的命名规则 fetchLogin --> useFetchLoginQuery
export const {
  useLoginMutation ,
  useLogoutMutation,
} = loginApi;

export default loginApi;
