import React, {useRef} from "react";
import { Form, Input, Button } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import styles from "./login.module.css";
import "./square.css";
import {useLoginMutation} from "../store/loginApi";
import {setUser} from '../store/reducer/userSlice'
import jwtDecode from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import { Helmet } from "react-helmet";
import Register from "../register";
import ForgotPassword from "../forgotPassword";

const LoginPage: React.FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login] = useLoginMutation()
  const registerRef:any = useRef()
  const forgotPasswordRef:any = useRef()

  {/* 登录提交表单数据 */}
  const onFinish = async (values: any) => {
    const resp: any = await login(values);
    if (resp.data.code !== 0)
      return
    const token = resp.data?.data
    localStorage.clear()
    localStorage.setItem("officialToken", token)
    const jwt_decode = jwtDecode(token)
    const user = JSON.parse(JSON.parse(JSON.stringify(jwt_decode))['whuiw-user'])
    dispatch(setUser({mobile: user['nickname'], expired: user['expired'], loginState: true, activate: user['activate']}))
    navigate("/home");
  };

  {/* 新用户注册弹框 */}
  const openRegister = () => {
    registerRef.current.showRegister(true)
  }

  {/* 忘记密码弹框 */}
  const openForgotPassword = () => {
    forgotPasswordRef.current.showForgotPassword(true)
  }


  return (
    <>
      <Helmet>
        <html lang={`zh`}></html>
        <title>{`登录`}</title>
      </Helmet>
      <div className={styles.container}>
        <div className={styles.loginContent}>
          <div className={styles.loginRight}>
            <div className={styles.logTit}>思库办公助手</div>
            <div className={styles.loginForm}>
              <Form
                name="normal_login"
                className="login-form"
                size="large"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的手机号",
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="请输入您的手机号"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "请输入您的密码",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="请输入您的密码"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item labelCol={{ flex: "flex" }}>
                  <Form.Item
                    name="remember"
                    valuePropName="checked"
                    noStyle
                    labelCol={{ sm: 6 }}
                  >
                    <a onClick={openForgotPassword}>忘记密码</a>
                  </Form.Item>

                  <a className={styles.loginForgot} onClick={openRegister}>新用户注册</a>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={false}
                    style={{ width: "100%" }}
                  >
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <Register ref={registerRef}/>
          <ForgotPassword ref={forgotPasswordRef}/>
        </div>
        <div className={styles.footer}>
          <div>
            <span>备案号: </span>
            <a href="https://beian.miit.gov.cn">鲁ICP备2021031867号-4</a>
          </div>
        </div>

        <div className="square">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className="circle">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>

    </>

  );
};

export default LoginPage;