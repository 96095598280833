import  { createSlice } from "@reduxjs/toolkit";
import  type {PayloadAction} from "@reduxjs/toolkit";
import {ResultStatusType} from "antd/es/result";

export interface ResultState {
  title: string | null
  state: ResultStatusType | null
  msg: string | null
  code: number | null
}

const initialState: ResultState = {
  title: null,
  state: 'success',
  msg: null,
  code: null
}

export const resultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    setResult(state, action: PayloadAction<ResultState>) {
      state.title = action.payload.title
      state.state = action.payload.state
      state.msg = action.payload.msg
      state.code = action.payload.code
    }
  },
})

export const { setResult } = resultSlice.actions
export const { reducer: resultReducer } = resultSlice;