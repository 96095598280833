import  { createSlice } from "@reduxjs/toolkit";
import  type {PayloadAction} from "@reduxjs/toolkit";
import React from "react";

export class Doc {
  key: React.Key
  docName: string
  createTime: string
  constructor(key: React.Key, docName: string, createTime: string) {
    this.key = key;
    this.docName = docName;
    this.createTime = createTime;
  }
}

export interface DocState {
  documents: Array<Doc>
  total: number
  keywords: Array<string>
  keyword: string | null
  currentPage: number
}


const initialState: DocState = {
  documents: [],
  total: 0,
  keywords: [],
  keyword: null,
  currentPage: 1
}

export const searchDocSlice = createSlice({
  name: 'searchDoc',
  initialState,
  reducers: {
    setDocs(state, action: PayloadAction<Array<Doc>>) {
      state.documents = [...action.payload]
    },
    setTotal(state, action: PayloadAction<number>){
      state.total = action.payload
    },
    setKeywords(state, action: PayloadAction<Array<string>>){
      state.keywords =  [...action.payload]
    },
    setKeyword(state, action: PayloadAction<string>){
      state.keyword =  action.payload
    },
    setCurrentPage(state, action: PayloadAction<number>){
      state.currentPage =  action.payload
    }
  },
})

export const {
  setDocs,
  setTotal,
  setKeywords,
  setKeyword,
  setCurrentPage
} = searchDocSlice.actions
export const { reducer: searchDocReducer } = searchDocSlice;